.event-content-spacing h5 {
    margin-bottom: 25px;
}

.custom-calendar {
    z-index: 1000;
}

.nav-panel-icon-arrow {
    height: 100%;
}

.nav-panel-icon-arrow > svg {
    height: 100%;
}
