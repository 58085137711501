.DatePicker {
    width: 100%;
}

/* Parkshare parking map classes */
.spot {
    cursor: no-drop;
}

.spot-available {
    cursor: pointer;
}

.spot-available polygon {
    fill: #179f7c;
}

.spot-available text {
    fill: white;
}

.turn-off {
    display: none;
}

/* Parkshare calander classes */
.redDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    border: 2px solid rgba(255, 36, 36, 1) !important;
    background: rgba(255, 36, 36, 0.7) !important;
}

.yellowDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    border: 2px solid rgba(228, 231, 36, 1) !important;
    background: rgba(255, 255, 36, 0.7) !important;
}

.ProseMirror:focus {
    outline: none;
}
